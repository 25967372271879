import React from "react"
import {Link, useStaticQuery, graphql} from "gatsby"
import iconSet from './selection.json';
import IcomoonReact from 'icomoon-react';
import {secondaryColor} from '../utils/color';

import style from './side-nav.module.css';

function SideNav({language}) {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___order], order: ASC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            icon
            language
            excludeFromNavigation
          }
        }
      }
    }
  }
  `)

  const nodes = data.allMarkdownRemark.edges;

  return (
    <div>
      <input
        type="checkbox"
        id="menuToggler"
        className={`${style.menuToggler} control-toggler`}/>
      <label htmlFor="menuToggler" className={`${style.menuOverlay} overlay`}></label>
      <ul className={style.sideMenu}>
        {nodes.map(({node}) => {
          const title = node.frontmatter.title || node.fields.slug
          if (node.frontmatter.language == language && !(node.frontmatter.excludeFromNavigation)) {
            return (
              <li className={style.menuItem} key={node.fields.slug}>

                <Link to={node.fields.slug}>
                  <IcomoonReact
                    icon={node.frontmatter.icon}
                    className={style.itemIcon}
                    iconSet={iconSet}
                    color={secondaryColor}
                    size={20}/>
                  <span className={style.itemTxt}>
                    {title}
                  </span>
                </Link>
              </li>
            )
          }

        })}
      </ul>
    </div>

  )
}

export default SideNav
