import React from 'react'
import PropTypes from 'prop-types'
import {Link, useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image'
import Search from "./search"

import style from "./header.module.css"

function Header({title , language}) {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "takasi.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`);

  return (

    <header className={style.header}>
      <div className={style.container}>
        <div className={`${style.topSection} flex flex-between`}>
          <div className="flex-item" style={{width: 100}}>
            <Link to="/">
              <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="logo" />
            </Link>
          </div>
          <div className="flex-item">
            <label htmlFor="menuToggler" className={style.menuIcon}>
              <div className={style.top}></div>
              <div className={style.middle}></div>
              <div className={style.bottom}></div>
            </label>
          </div>
        </div>
        <div className={`${style.bottomSection} mr-t100 mr-t70-xs full-width-xs`}>
          <h1 className="txt_primary-clr txt-center">{title}</h1>
          <Search language={language} />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
siteTitle: PropTypes.string,
};

Header.defaultProps = {
siteTitle: ``,
};

export default Header;
