import React from "react"
import { Link , navigate} from "gatsby"
import Header from "./header"
import SideNav from "./side-nav"
import TableOfContent from "./table-content"
import iconSet from './selection.json';
import IcomoonReact from 'icomoon-react';
import {secondaryColor} from '../utils/color';

import './layout.css';

function Layout(props) {
  const {title, children, language, tableOfContents , therIsTableOfContents} = props;

  return (
    <>
      <Header title={title} language={language} />
      <div className="flex">
        <div className="flex-item">
          <SideNav language={language}/>
        </div>
        <div className="flex-item" style={{minWidth:'calc(100% - 300px)'}}>
          <div className="main-content flex flex-between padd-end-zero">
            <main className="content flex-item">{children}</main>
            <div className={therIsTableOfContents? 'flex-item' : 'hide' }>
              <input type="checkbox" id="TBCToggler" className="control-toggler"/>
              <label htmlFor="TBCToggler" className="overlay transparent"></label>
              <label htmlFor="TBCToggler" className="tbc-lbl">
                <IcomoonReact
                icon="double_arrow"
                className="tbc-icon"
                iconSet={iconSet}
                color={secondaryColor}
                size={15}/>
              </label>
              <div className="table-content-container">
                <TableOfContent links={tableOfContents} className="flex-item"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
