import React from "react";
import { Link } from "gatsby"
import { Highlight } from 'react-instantsearch-dom';
import style from './search.module.css'

const HitPreview = ({hit, language}) => {
  const desc = hit.description;

  if(hit.language == 'es'){
  return (

        <div className={style.hitResult} key={hit.fields.slug}>
          <h5 className={style.resultTitle}>
            <Highlight attribute="title" hit={hit} />
          </h5>
          <Link
            to={hit.fields.slug}
            className={`${style.resultLink} ${hit.headings.length ? 'flex' : style.fullWidth }`}>
              <p
               className={`${style.resultDesc} flex-item`}
               dangerouslySetInnerHTML={{__html:desc}}
              />
            <ul className={`${style.resultSubtitles} flex-item`}>
              {hit.headings.map(heading =>
                <li className={style.subtitleItem} key={heading.value}>{heading.value}</li>
              )}
            </ul>
          </Link>
        </div>

  )

    }
    else{
     return null
    }
}

export default HitPreview

/* <div key={hit.fields.slug}>
        <h5 className="hit-result-header">
          <Link style={{ boxShadow: `none` }} to={hit.fields.slug}>
            <Highlight attribute="title" hit={hit} />
          </Link>
        </h5>
        <p className="hit-result-desc" dangerouslySetInnerHTML={{__html:desc}}/>
        <p><Highlight attribute="desc" hit={hit} /></p>
        {hit.headings.map(heading =>
          <div>{heading.value}</div>
        )}
        <Highlight key={heading.value} attribute="heading.value" hit={hit}>{heading.value}</Highlight>
        </div>*/
