import React from 'react';

import './table-content.module.css';
function TableOfContent(links) {
  // if (!links || !links.length) {
  //   return null;
  // }

  return (
   <div dangerouslySetInnerHTML={{ __html: links.links }}></div>
  );
};

export default TableOfContent
